<template>
  <b-form>
    <b-card title="Ekle">
      <b-form-group
        label="Başlık"
        label-for="title"
      >
        <b-form-input
          id="title"
          v-model="fixedEducation.title"
          placeholder="Başlık"
        />
      </b-form-group>
      <b-form-group
        label="Metin"
        label-for="content"
      >
        <b-form-textarea
          id="content"
          v-model="fixedEducation.content"
          placeholder="Metin"
        />
      </b-form-group>
      <b-form-group
        label="Eğitim Tipi"
        label-for="educationTypes"
      >
        <v-select
          id="educationTypes"
          v-model="fixedEducation.id_com_portal_fixed_education_types"
          placeholder="Eğitim Tipi"
          :options="educationTypes"
          label="title"
          :reduce="educationType => educationType.id"
        />
      </b-form-group>
    </b-card>
    <b-card title="Dosya">
      <b-row>
        <b-col
          v-if="fixedEducation.file"
          cols="2"
        >
          <b-button
            variant="primary"
            :href="folderUrl + fixedEducation.file"
            target="_blank"
            block
          >
            <FeatherIcon
              icon="DownloadIcon"
              size="48"
            />
            <br>
            Dosya İndir
          </b-button>
        </b-col>
        <b-col>
          <b-form-group
            label="Dosya Yükle"
            label-for="file"
          >
            <b-form-file
              id="file"
              v-model="fixedEducation.upload_doc"
              placeholder="Bir dosya seçin veya buraya bırakın..."
              drop-placeholder="Dosyayı buraya bırakın..."
              browse-text="Dosya Seçin"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
    <b-button
      variant="danger"
      :disabled="submitStatus"
      @click="submitForm"
    >
      Kaydet
    </b-button>
  </b-form>
</template>

<script>
import {
  BForm,
  BCard,
  BFormInput,
  BFormTextarea,
  BFormFile,
  BFormGroup,
  BButton,
  BRow,
  BCol,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'Edit',
  components: {
    BForm,
    BCard,
    BFormInput,
    BFormTextarea,
    BFormFile,
    BFormGroup,
    BButton,
    vSelect,
    BRow,
    BCol,
  },
  data() {
    return {
      submitStatus: false,
      folderUrl: null,
    }
  },
  computed: {
    educationTypes() {
      return this.$store.getters['fixedEducationTypes/getFixedEducationTypes']
    },
    fixedEducation() {
      const data = this.$store.getters['fixedEducations/getFixedEducation']
      if (data.file) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.folderUrl = `${this.$store.state.app.baseURL}/media/portal/fixed_educations/`
      }
      return data
    },
    saveStatus() {
      return this.$store.getters['fixedEducations/getFixedEducationSaveStatus']
    },
  },
  watch: {
    saveStatus(val) {
      if (val.status === true) {
        this.getFixedEducations()
        const config = {
          icon: 'success',
          title: 'İşlem Başarılı!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      } else if (val.status === false) {
        const config = {
          icon: 'error',
          title: 'İşlem Hatası!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      }
      this.submitStatus = false
    },
  },
  created() {
    this.$store.dispatch('fixedEducations/fixed_educationReset')
    this.getFixedEducations()
    this.getFixedEducationTypes()
  },
  methods: {
    getFixedEducations() {
      this.$store.dispatch('fixedEducations/fixed_educationView', this.$route.params.id)
    },
    getFixedEducationTypes() {
      this.$store.dispatch('fixedEducationTypes/fixedEducationTypesList')
    },
    sweetAlert(config) {
      this.$swal({
        icon: config.icon,
        title: config.title,
        text: config.text,
        html: config.html,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText: config.buttonText,
        buttonsStyling: false,
      })
    },
    submitForm() {
      this.fixedEducation.submitStatus = true
      this.$store.dispatch('fixedEducations/fixed_educationSave', this.fixedEducation)
    },
  },
}
</script>
